<template>
  <div class="page">Signature</div>
</template>
<script>
export default {
  name: "Signature",
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss"></style>
